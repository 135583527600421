/*
 * Custom fonts for Konva's Text objects.
 */

@font-face {
  font-family: "Arial Normal";
  src: url(data/fonts/Arial.ttf) format("truetype");
}

@font-face {
  font-family: "Arial Bold";
  src: url(data/fonts/Arial_Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Arial Italic";
  src: url(data/fonts/Arial_Italic.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans Regular";
  src: url(data/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans Bold";
  src: url(data/fonts/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans Semibold";
  src: url(data/fonts/OpenSans-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "Calibri";
  src: url(data/fonts/Calibri.ttf) format("truetype");
}

@font-face {
  font-family: "ElectroluxSans Regular";
  src: url(data/fonts/ElectroluxSans-Regular.otf) format("opentype");
}

@font-face {
  font-family: "ElectroluxSans SemiBold";
  src: url(data/fonts/ElectroluxSans-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: "SamsungSharp SansBd";
  src: url(data/fonts/SamsungSharpSansBd.ttf) format("truetype");
}

@font-face {
  font-family: "CorpidE3SCd 700";
  src: url(data/fonts/CorpidE3SCd_700_.svg#svgCorpid) format("svg"),url(data/fonts/CorpidE3SCd_700.woff) format("woff");
}

@font-face {
  font-family: "CorpidE3SCd 500";
  src: url(data/fonts/CorpidE3SCd_500_.svg#svgCorpid) format("svg"), url(data/fonts/CorpidE3SCd_500.woff) format("woff");
}

@font-face {
  font-family: "CirceRounded-Regular";
  src: url(data/fonts/CirceRounded-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "CirceRounded-Bold";
  src: url(data/fonts/CirceRounded-Bold.ttf) format("truetype");
}